var SockWrap = function(io, forcedLongPolling, redux) {
	var cs = {}
	, ack = function(what, args) {
		var cb;
		if (args.length == 0) return;
		cb = args[args.length-1];
		if (typeof cb !== 'function') return;
		cb(what);
	}
	;
	if(redux) {
		cs.store = redux.store;
		cs.actionPrefix = redux.actionPrefix;
	}
	cs.io = io;
	cs.forcedLongPolling = forcedLongPolling;
	cs.dispatch = function(event, args) {
		if(cs.store) {
			cs.store.dispatch({
				type: cs.actionPrefix + event,
				payload: {event: event, args: args}
			});
		}
	};
	cs.encode = function(str) {
		return encodeURIComponent(str);
	};
	cs.decode = function (str) {
		return decodeURIComponent(str);
	};
	cs.on = function(what, callback) {
		cs.io.on(what, function() {
			var args = new Array(arguments.length);
			for(var i = 0; i < args.length; ++i) {
				args[i] = arguments[i];
			}
			cs.dispatch(what, args);
			callback.apply(null, args);
			ack('plain', args)
		});
	};
	cs.onString = function(what, callback) {
		cs.io.on(what, function(data) {
			arguments[0] = cs.decode(data);
			var args = new Array(arguments.length);
			for(var i = 0; i < args.length; ++i) {
				args[i] = arguments[i];
			}
			cs.dispatch(what, args);
			callback.apply(null, args);
			ack('onString', args)
		});
	};
	cs.onJson = function(what, callback) {
		cs.io.on(what, function(data) {
			var decoded, json;
			try {
				decoded = cs.decode(data);
			} catch (e) {
				console.log("ERROR SockWrap.decode(): error decoding data: [" + data + "]: " + e);
			}
			try {
				json = JSON.parse(decoded);
			} catch (e) {
				console.log("ERROR SockWrap.decode(): error parsing json `" + decoded + " `: " + e);
			}
			arguments[0] = json;
			var args = new Array(arguments.length);
			for(var i = 0; i < args.length; ++i) {
				args[i] = arguments[i];
			}
			cs.dispatch(what, args);
			callback.apply(null, args);
			ack('onJson', args)
		});
	};
	cs.emit = function(what, data) {
		switch (typeof data) {
			case "object":
				arguments[1] = cs.encode(JSON.stringify(data));
				break;
			case "string":
				arguments[1] = cs.encode(data);
				break;
		}
		var args = new Array(arguments.length);
		for(var i = 0; i < args.length; ++i) {
			args[i] = arguments[i];
		}
		return new Promise(function(resolve, reject) {
			if(args.length <= 0 || typeof args[args.length-1] !== 'function') {
				args.push(function() {
					var ags = new Array(arguments.length);
					for(var i = 0; i < ags.length; ++i) {
						ags[i] = arguments[i];
					}
					resolve({args: ags});
				});
			} else {
				const cb = args[args.length-1];
				args[args.length-1] = function() {
					var ags = new Array(arguments.length);
					for(var i = 0; i < ags.length; ++i) {
						ags[i] = arguments[i];
					}
					cb.apply(null, ags);
					resolve({args: ags});
				}
			}
			cs.io.emit.apply(cs.io, args);
		});
	};
	cs.connect = function() {
		cs.io.connect();
	};
	cs.disconnect = function() {
		cs.io.disconnect();
	};
	return cs;
};

export default SockWrap;
